import { observable, action } from 'mobx';
import { Entity } from '@axl/accel-framework/models';

export default class SchoolLibrary extends Entity {
    constructor(library?: Partial<SchoolLibrary>) {
        super(library);
    }

    @observable title: string;
    @observable shortDescription: string;
    @observable freeAccess: boolean;
    @observable primaryImageCloudKey: string;
    @observable studentAccessDate?: Date;
    @observable flowBeginDate?: Date;
    @observable studentHasAccess: boolean;
    @observable categories: string[];

    static fromJson(json: any): SchoolLibrary {
        return new SchoolLibrary({
            ...json,
            studentAccessDate: json.studentAccessDate ? new Date(json.studentAccessDate) : undefined,
            flowBeginDate: json.flowBeginDate ? new Date(json.flowBeginDate) : undefined,
        });
    }

    @action
    update(library: Partial<SchoolLibrary>) {
        super.update(library);
    }
}

