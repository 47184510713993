import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';
import { CourseItemTaskAnswerComment, CourseItemTaskAnswerCommentFilter } from '@axl/accel-framework/models';

export class FetchTaskAnswerCommentsRequest extends ApiRequest {
    public method = HttpMethod.GET;
    
    public url: string = `/student-course-item/task-answer/comment`;
    public body = {
        ...this.filter,
    }

    constructor(public filter?: CourseItemTaskAnswerCommentFilter) {
        super();
    }
}

export class FetchTaskAnswerCommentsResponse extends ApiResponse {
    public items: CourseItemTaskAnswerComment[];
    public filter: CourseItemTaskAnswerCommentFilter;

    constructor(response: any) {
        super(response);
        this.filter = new CourseItemTaskAnswerCommentFilter(this.body.filter);
        this.items = this.body.items.map(CourseItemTaskAnswerComment.fromJson);
    }
}