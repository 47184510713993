import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { InviteStatus } from '@axl/accel-framework/enums';

export class CheckInviteRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = `/authorization/check-invite/${this.token}`;

    constructor(public token: string) {
        super();
    }
}

export class CheckInviteResponse extends ApiResponse {
    public status: InviteStatus;
    constructor(response?: any) {
        super(response);
        this.status = this.body;
    }
}