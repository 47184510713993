import { BaseFilter, Entity, SchoolContentItemType, SchoolContentSectionType } from '@axl/accel-framework/models';
import { observable, action, computed } from 'mobx';
import SchoolCourse from '../school-course/school-course';
import SchoolLibrary from '../school-library/school-library';
import { SchoolCourseType } from '../school-course/school-course-filter';


export class SchoolContentItem extends Entity {
    constructor(item?: Partial<SchoolContentItem>) {
        super(item);
        if (item) this.update(item);
    }

    @observable title: string;
    @observable description: string;
    @observable coverCloudKey: string;

    @observable createdDate: Date;
    @observable updatedDate: Date;
    @observable position: number;
    @observable parentId: string | null;
    @observable parent: SchoolContentItem | null;
    @observable course?: SchoolCourse;
    @observable library?: SchoolLibrary;
    @observable type: SchoolContentItemType;
    @observable sectionType: SchoolContentSectionType;
    @observable children: SchoolContentItem[];

    @computed get progress(): number {
        if(this.sectionType == SchoolContentSectionType.Library) return 0;
        if (this.type == SchoolContentItemType.Item)
            return this.course?.studentCourse?.progress ?? 0;

        let progress = 0;
        this.children.forEach(x => progress += x.progress);
        progress /= this.children.length;
        return progress;
    }

    @computed get itemCount() {
        if (this.type == SchoolContentItemType.Item) return 1;
        let count = 0;
        for (const child of this.children) {
            count += child.itemCount;
        }
        return count;
    }

    static fromJson(json: any): SchoolContentItem {
        const item = new SchoolContentItem({
            id: json.id,
            title: json.title,
            description: json.description,
            type: json.type,
            sectionType: json.sectionType,
            position: json.position,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            coverCloudKey: json.coverCloudKey,
            parentId: json.parentId,
            parent: json.parent ? SchoolContentItem.fromJson(json.parent) : json.parent === null ? null : undefined,
            course: json.course ? SchoolCourse.fromJson(json.course) : undefined,
            library: json.library ? SchoolLibrary.fromJson(json.library) : undefined,
            children: json.children ? json.children.map(SchoolContentItem.fromJson) : []
        });
        item.children.forEach(x => x.update({ parent: item }));
        return item;
    }

    @action
    update(changes: Partial<SchoolContentItem>) {
        super.update(changes);
    }
}

export class SchoolContentItemFilter extends BaseFilter<SchoolContentItem> {
    constructor(filter?: Partial<SchoolContentItemFilter>) {
        super();
        if (filter) this.update(filter);
    }

    courseType: SchoolCourseType;
    onlyAvailable: boolean;
    sectionType: SchoolContentSectionType;
    
    update(changes: Partial<SchoolContentItemFilter>) {
        super.update(changes);
    }
}