import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class ResetPasswordRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = '/authorization/reset-password';
    public body: any = {
        token: this.token,
        password: this.password,
        confirmPassword: this.confirmPassword,
    };
    public authorized = false;

    constructor(public token: string,
        public password: string,
        public confirmPassword: string) {
        super();
    }
}

export class ResetPasswordResponse extends ApiResponse {
    constructor(response?: any) {
        super(response);
    }
}