import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';
import { SchoolContentItemComment, SchoolContentSectionType } from '@axl/accel-framework/models';

export type CreateCourseItemComment = {
    targetItemId: string;
    sectionType: SchoolContentSectionType;
    targetProgressId: string;
    ratingId?: string;
    parentId?: string;
    replyId?: string;
    text: string;
    fileIds: string[];
}
export class CreateCourseItemCommentRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = '/school-content-item-comment';
    public body: CreateCourseItemComment = {
        targetItemId: this.targetItemId,
        sectionType: this.sectionType,
        targetProgressId: this.targetProgressId,
        ratingId: this.ratingId,
        parentId: this.comment.parentId,
        replyId: this.comment.replyId,
        text: this.comment.text,
        fileIds: this.comment.files?.map(x => x.id)
    };

    constructor(public comment: SchoolContentItemComment,
        public sectionType: SchoolContentSectionType,
        public targetItemId: string,
        public targetProgressId: string,
        public ratingId?: string) {
        super();
    }
}

export class CreateCourseItemCommentResponse extends ApiResponse {
    public id: string;
    constructor(response: any) {
        super(response);
        this.id = this.body;
    }
}