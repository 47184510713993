import { Duration } from '@axl/accel-framework/components';
import { TimeSpan, combineClasses } from '@axl/accel-framework/utils';
import { Skeleton, Statistic } from 'antd';
import { Price, useLoc } from 'components';
import React, { useMemo, useState } from 'react';
import Humanize from 'humanize-plus';
import { AnalyticsItem } from 'models';


export type AnalyticsItemStatisticProps = {
    item: AnalyticsItem;
    hideSubtitle?: boolean;
    titleExtra?: React.ReactNode;
    className?: string;
    titleClassName?: string;
    onClick?: () => void;
}

const AnalyticStatistic: React.FC<AnalyticsItemStatisticProps> = ({ item, hideSubtitle, titleExtra, className, titleClassName, onClick }) => {
    const loc = useLoc();

    const [clickable] = useState(onClick !== undefined);
    
    const formattedValue = useMemo(() => {
        switch (item.valueType) {
            case 'currency':
                return <Price
                    price={item.value}
                    precision={2}
                    truncateIfFractionalZero
                    fractionalClassname='fs-23' />;
            case 'percent':
                return `${item.value} %`;
            case 'timespan':
                return <Duration duration={TimeSpan.fromSeconds(item.value)} includeSeconds={false} format='text' convertDaysToHours />;
            case 'fileSize':
                return Humanize.fileSize(item.value, 1);
            case 'value':
            default:
                return item.value;
        }
    }, [item]);

    return <div onClick={onClick} className={combineClasses(clickable ? 'c-pointer hoverable' : undefined, className)}>
        <Statistic
            title={<>
                <div className={titleClassName}>
                    {/* for backward compatibility, remove `.sales.` loc later */}
                    {loc.hasWord(`Analytics.item.type.${item.type}`)
                        ? loc.word(`Analytics.item.type.${item.type}`, { default: item.type })
                        : loc.word(`Analytics.item.type.${item.type}`, { default: item.type })}
                    {titleExtra}
                </div>
                {hideSubtitle !== true &&
                    <div className='fs-14'>{
                        loc.word(`Analytics.item.type.${item.type}.subtitle`, { default: item.type })}
                    </div>}
            </>}
            // value doesnt matter
            value={0}
            valueRender={() => <>
                <div className='fs-24 nowrap'>
                    {formattedValue}
                </div>
            </>}
        />
    </div>;
}

AnalyticStatistic.defaultProps = {
    titleClassName: 'fw-600 fs-16 lh-19 color-gray-9',
}

export default AnalyticStatistic;


const AnalyticStatisticSkeleton: React.FC = () => {
    return <Statistic valueRender={() => <Skeleton active paragraph={{ rows: 0 }} />}  />;
}
export { AnalyticStatisticSkeleton };