import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';
import { SchoolContentItemComment, CourseItemCommentState } from '@axl/accel-framework/models';

export type SaveCourseItemComment = {
    id: string;
    text: string;
    replyId?: string;
    fileIds: string[];
    state: CourseItemCommentState;
}

export class SaveCourseItemCommentRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = `/school-content-item-comment`;
    public body: SaveCourseItemComment = {
        id: this.comment.id,
        text: this.comment.text,
        fileIds: this.comment.files?.map(x => x.id),
        state: this.comment.state,
        replyId: this.comment.replyId
    }

    constructor(public comment: SchoolContentItemComment) {
        super();
    }
}

export class SaveCourseItemCommentResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}