import { Api } from "@axl/accel-framework";
import { CrudApi } from "@axl/accel-framework/api";
import { GamificationAchievement, GamificationAchievementFilter, GamificationLeader, GamificationLeaderFilter, GamificationProduct, GamificationProductFilter, GamificationTransaction } from "@axl/accel-framework/models";
import { AnalyticsItem } from "models";

export default class GamificationApi {
    constructor(private api: Api) {
    }

    fetchTotals = () => this.api.get<{ items: AnalyticsItem[] }>('/gamification/totals');
    fetchBalance = () => this.api.get<number>('/gamification/balance');

    fetchAchievements = (filter: GamificationAchievementFilter) => this.api.getList<GamificationAchievement>('/gamification/achievement', undefined, filter,
        body => ({
            items: body.items.map(GamificationAchievement.fromJson),
            filter: new GamificationAchievementFilter(body.filter)
        }));

    fetchLeaders = (filter: GamificationLeaderFilter) => this.api.getList<GamificationLeader>('/gamification/leaders', undefined, filter,
        body => ({
            items: body.items.map(GamificationLeader.fromJson),
            filter: new GamificationLeaderFilter(body.filter),
        }));

    product = new GamificationProductApi(this.api);
    transaction = new GamificationTransactionApi(this.api);
}

export class GamificationProductApi extends CrudApi<GamificationProduct> {
    constructor(private api: Api) {
        super(api, {
            endpoint: '/gamification/product',
            parse: e => GamificationProduct.fromJson(e),
            defaultFields: ``
        });
    }

    pay = (id: string) => this.api.post(this.settings.endpoint, { productId: id });
}

export class GamificationTransactionApi extends CrudApi<GamificationTransaction> {
    constructor(private api: Api) {
        super(api, {
            endpoint: '/gamification/transaction',
            parse: e => GamificationTransaction.fromJson(e),
            defaultFields: ``
        });
    }
}