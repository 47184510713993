import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { InputSearch, Loc, useUpdateEffect } from "@axl/accel-framework/components";
import { combineClasses } from "@axl/accel-framework/utils";
import { Button } from "antd";
import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";

type Props = {
    wrapperClassName?: string;
    inputClassName?: string;
    onSearch?: (search: string) => void;
};

const ItemTreeSearch: React.FC<Props> = ({ wrapperClassName, inputClassName, onSearch }) => {
    const [searchOpen, setSearchOpen] = useState(false);

    const handleSearch = useCallback((s: string) => {
        onSearch?.(s);
    }, [onSearch]);

    useUpdateEffect(() => {
        if (!searchOpen) {
            onSearch?.('');
        }
    }, [searchOpen]);

    return <div className={combineClasses('flex align-center justify-between gap-10 p-15', wrapperClassName)}>
        {searchOpen && <InputSearch autoFocus
            onSearch={x => handleSearch(x)}
            debounce={100}
            debounceSettings={{ trailing: true }}
            className={combineClasses(`flex-1`, inputClassName)} />}

        <Button type='link' icon={!searchOpen ? <SearchOutlined /> : <CloseOutlined />} onClick={() => setSearchOpen(!searchOpen)} className='p-0 h-auto'>
            {!searchOpen && <Loc word='Search' default='Search' />}
        </Button>
    </div>;
}

export default observer(ItemTreeSearch);