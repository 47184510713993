import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';
import { Lang } from '@axl/accel-framework/enums';

export class SelectLangRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = '/student/select-lang';
    public body: any = {
        lang: this.lang
    };

    constructor(public lang: Lang) {
        super();
    }
}

export class SelectLangResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}