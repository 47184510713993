import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { ForumTopic, ForumThread } from '@axl/accel-framework/models';
import type { BanStatus } from '../models/ban-status';

export class FetchTopicRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public fields = `{
        canModerate,
        topic{
            id,
            title,
            text,
            createdDate,
            updatedDate,
            lastActivityDate,
            commentCount,
            student{
                id,
                firstName,
                lastName,
                email,
                avatar {
                    id,
                    cloudKey
                }
            },
            files{
                id,
                name,
                cloudKey,
                extension,
                size,
                type
            },
            likeCount,
            dislikeCount,
            currentUserReaction{
                id,
                type
            }
        },
        breadcrumbs(filter:"{useSort:false}"){
            id, 
            title
        },
        banStatus{
            type,
            untilDate
        }
    }`;
    public url: string = `/forum/topic/${this.topicId}`;

    constructor(public topicId: string) {
        super();
    }
}

export class FetchTopicResponse extends ApiResponse {
    canModerate: boolean;
    topic: ForumTopic;
    breadcrumbs: ForumThread[];
    banStatus: BanStatus | null;
    constructor(response: any) {
        super(response);
        this.canModerate = this.body.canModerate;
        this.topic = ForumTopic.fromJson(this.body.topic);
        this.breadcrumbs = this.body.breadcrumbs.map(ForumTopic.fromJson);
        this.banStatus = this.body.banStatus
            ? {
                ...this.body.banStatus,
                untilDate: this.body.banStatus.untilDate ? new Date(this.body.banStatus.untilDate) : null
            }
            : null;
    }
}
