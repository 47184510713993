import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class FetchEmailNotificationsSubscriptionStatusRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = `/authorization/email-notifications-subscription-status?studentId=${this.studentId}&email=${this.email}`;

    constructor(public studentId: string, public email:string) {
        super();
    }
}

export class FetchEmailNotificationsSubscriptionStatusResponse extends ApiResponse {
    public subscribed: boolean;
    constructor(response: any) {
        super(response);
        this.subscribed = this.body;
    }
}