import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export type UpdateTopicRequestModel = {
    id: string,
    text: string,
    files: string[]
}

export class UpdateTopicRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = '/forum/topic';
    public body = {
        ...this.model
    }

    constructor(public model: UpdateTopicRequestModel) {
        super();
    }
}

export class UpdateTopicResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}