import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class LoginRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = '/authorization/login';
    public body: any = {
        contactId: this.contactId,
        email: this.email,
        password: this.password,
        rememberMe: true
    };
    public authorized = false;

    constructor(public email: string, public password: string, public contactId?: string) {
        super();
    }
}

export class LoginResponse extends ApiResponse {
    constructor(response?: any) {
        super(response);
    }
}