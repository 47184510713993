import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class DeleteTopicCommentRequest extends ApiRequest {
    public method = HttpMethod.DELETE;
    public url: string = `/forum/topic/comment/${this.commentId}`;

    constructor(public commentId: string) {
        super();
    }
}

export class DeleteTopicCommentResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}