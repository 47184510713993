import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class FetchContactRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = `/authorization/contact/${this.contactId}`;

    constructor(public contactId: string) {
        super();
    }
}

export class FetchContactResponse extends ApiResponse {
    public id: string;
    public email: string;
    public isRegistered: boolean;
    constructor(response: any) {
        super(response);
        this.id = this.body.id,
        this.email = this.body.email,
        this.isRegistered = this.body.isRegistered
    }
}
