import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';
import { ForumThread, ForumTopic } from '@axl/accel-framework/models';
import type { BanStatus } from '../models/ban-status';

export class FetchForumThreadRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public fields = `{
        canModerate,
        thread{
            id,
            title,
            description,
            designHTMLPublished,
            designStylePublished,
            canStudentCreateTopics,
            createdDate,
            updatedDate,
            lastActivityDate,
            topicCount,
            commentCount
        },
        threads{
            id,
            title,
            description,
            createdDate,
            updatedDate,
            lastActivityDate,
            topicCount,
            commentCount
        },
        topics{
            id,
            title,
            text,
            createdDate,
            updatedDate,
            lastActivityDate,
            commentCount,
            lastComment{
                id,
                createdDate,
                student{
                    id,
                    firstName,
                    lastName
                }
            },
            student{
                id,
                firstName,
                lastName,
                email
            }
        },
        breadcrumbs(filter:"{useSort:false}"){
            id, 
            title
        },
        banStatus{
            type,
            untilDate
        }
    }`;
    public url: string = this.threadId ? `/forum/thread/${this.threadId}` : '/forum/thread';

    constructor(public threadId: string | null) {
        super();
    }
}

export class FetchForumThreadResponse extends ApiResponse {
    canModerate: boolean;
    thread: ForumThread | null;
    threads: ForumThread[];
    topics: ForumTopic[];
    breadcrumbs: ForumThread[];
    banStatus: BanStatus | null;
    constructor(response: any) {
        super(response);
        this.canModerate = this.body.canModerate;
        this.thread = this.body.thread ? ForumThread.fromJson(this.body.thread) : null;
        this.threads = this.body.threads.map(ForumThread.fromJson);
        this.topics = this.body.topics.map(ForumTopic.fromJson);
        this.breadcrumbs = this.body.breadcrumbs.map(ForumThread.fromJson);
        this.banStatus = this.body.banStatus
            ? {
                ...this.body.banStatus,
                untilDate: this.body.banStatus.untilDate ? new Date(this.body.banStatus.untilDate) : null
            } : null
    }
}