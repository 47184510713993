import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { StudentCourse } from 'models';

export class FetchStudentCourseRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public fields = `{
        id,
        beginDate,
        endDate,
        state,
        flowBeginDate,
        accessBeginDate,
        course{
            id,
            name,
            dontAskFeedback
        },
        currentCourseItem{
            id
        },        
        student{
            id,
            firstName,
            lastName
        },
        progress
    }`;
    public url: string = `/student-course-preview/${this.studentCourseId}`;

    constructor(public studentCourseId: string) {
        super();
    }
}

export class FetchStudentCourseResponse extends ApiResponse {
    studentCourse: StudentCourse;
    constructor(response: any) {
        super(response);
        this.studentCourse = StudentCourse.fromJson(this.body);
    }
}