import { ContactCourseFilter } from '@axl/accel-framework/models';

export default class StudentCourseFilter extends ContactCourseFilter {
    constructor(filter?: Partial<StudentCourseFilter>) {
        super(filter);
        if (filter) this.update(filter);
    }

    update(changes: Partial<StudentCourseFilter>) {
        super.update(changes);
    }
}
