import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';
import { UserNotificationFilter } from '@axl/accel-framework/models';

export class FetchCountNotificationsRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = `/notification/count`;
    public body = {
        ...this.filter,
    }

    constructor(public filter?: UserNotificationFilter) {
        super();
    }
}

export class FetchCountNotificationsResponse extends ApiResponse {
    public count: number;
    constructor(response: any) {
        super(response);
        this.count = this.body;
    }
}