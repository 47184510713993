import React, { useEffect, useMemo } from 'react';
import { provider, useInstances } from 'react-ioc';
import { ConfigProvider, message } from 'antd';
import { observer } from 'mobx-react';
import { Lang } from '@axl/accel-framework/enums';
import { AccelProvider, NewRelic } from '@axl/accel-framework/components';
import ruRU from 'antd/es/locale/ru_RU';
import enUS from 'antd/es/locale/en_US';
import idID from 'antd/es/locale/id_ID';
import ukUA from 'antd/es/locale/uk_UA';
import esES from 'antd/es/locale/es_ES';
import zhTW from 'antd/es/locale/zh_TW';
import zhCN from 'antd/es/locale/zh_CN';

import moment from 'moment';
import 'moment/dist/locale/ru';
import 'moment/dist/locale/uk';
import 'moment/dist/locale/id';
import 'moment/dist/locale/es';
import 'moment/dist/locale/zh-tw';
import 'moment/dist/locale/zh-cn';

import { env, Env } from '@axl/accel-framework/enums';
import DevActions from './shared/DevActions/DevActions';
import Router from './Router';
import LocProvider from './shared/providers/LocProvider/LocProvider';
import UserProvider from './shared/providers/UserProvider/UserProvider';
import SchoolProvider from './shared/providers/SchoolProvider/SchoolProvider';
import GamificationBalanceProvider from './shared/providers/GamificationBalanceProvider/GamificationBalanceProvider';
import { ScriptInjection, useLang, useSchool, useSettings } from 'components';
import { toValue, toFactory, toExisting } from "react-ioc";
import appSettings, { AppSettings } from "models/app-settings";
import Api, { JwtApiClient } from 'api';
import BaseApi from '@axl/accel-framework/api';
import { UserStore } from 'stores';
import { UIStore } from 'stores';
import { Localization, JwtDecoder, KeyGenerator, LangDetector, CookieStorage, AuthCookieClient, GeoLocator } from '@axl/accel-framework/utils';
import { LocalizationStore, TokenStore, DeviceStore } from '@axl/accel-framework/stores';
import { ApiClient } from '@axl/accel-framework/api';
import { FileProvider } from '@axl/accel-framework/utils';
import { HubClient } from '@axl/accel-framework/hub-client';
import { SchoolStore } from 'stores';
import ServiceStore from 'stores/ServiceStore/ServiceStore';

import '@axl/accel-framework/utils/extensions/index';

import 'antd/dist/antd.min.css';
// should be the last one
import './styles/global.scss';
import NotificationCenterStore from './shared/NotificationCenter/NotificationCenterStore';
import NotificationCenter from './shared/NotificationCenter/NotificationCenter';

const App: React.FC = () => {
    const settings = useSettings();
    return <>
        {settings.newRelic.enabled && <NewRelic {...settings.newRelic} />}
        <SchoolProvider>
            <LocProvider>
                <UserProvider>
                    <AppConfig />
                </UserProvider>
            </LocProvider>
        </SchoolProvider>
    </>;
}

const AppConfig: React.FC = observer(() => {
    const [
        loc,
        keygen,
        api,
        fileProvider,
        userStore,
        deviceStore,
        geoLocator,
    ] = useInstances(Localization, KeyGenerator, Api, FileProvider, UserStore, DeviceStore, GeoLocator);

    const { lang } = useLang();
    const school = useSchool();

    useMemo(() => {
        switch (lang) {
            case Lang.zhtw:
                moment.locale('zh-tw');
                break;
            case Lang.zhcn:
                moment.locale('zh-cn');
                break;
            default: moment.locale(lang);
        }
    }, [lang]);

    useEffect(() => {
        message.config({ top: 70 });
    }, []);

    const antLocale = useMemo(() => {
        switch (lang) {
            case Lang.en: return enUS;
            case Lang.id: return idID;
            case Lang.ru: return ruRU;
            case Lang.uk: return ukUA;
            case Lang.es: return esES;
            case Lang.zhtw: return zhTW;
            case Lang.zhcn: return zhCN;
        }
    }, [lang]);

    return <ConfigProvider locale={antLocale}>
        <AccelProvider loc={loc}
            keygen={keygen}
            api={api}
            fileProvider={fileProvider}
            lang={lang}
            user={userStore.currentUser}
            deviceStore={deviceStore}
            geoLocator={geoLocator}>

            <ScriptInjection script={school.jsHeadCode} target='head' />
            <ScriptInjection script={school.jsBodyCode} target='body' />

            <GamificationBalanceProvider>
                <Router />
            </GamificationBalanceProvider>
            {env == Env.dev && <DevActions />}
            {userStore.isAuthorized && <NotificationCenter />}

        </AccelProvider>
    </ConfigProvider>;
})
export default provider(
    // settings
    [AppSettings, toValue(appSettings)],

    // utils
    KeyGenerator,
    [FileProvider, toFactory([AppSettings], (settings) => new FileProvider(settings.aws))],
    JwtDecoder,
    CookieStorage,
    [GeoLocator, toFactory([CookieStorage], (storage) => new GeoLocator(storage))],

    // hub
    [HubClient, toFactory([UserStore, AppSettings], (userStore, settings) => new HubClient(userStore, { host: settings.hub.url, invokeTimeoutMs: 5000 }))],

    // api
    [ApiClient, toFactory([UserStore, AppSettings, HubClient], (userStore, settings, hub) => new JwtApiClient(settings.api.url, userStore, hub))],
    [Api, toFactory([ApiClient], (client) => new Api(client))],
    [BaseApi, toExisting(Api)],

    //loc
    /**
     * school lang priority 1. cookies 2. school language
     * so if we dont have cookie lang, then lang will be determined by the school lang later
     */
    [LangDetector, toValue(new LangDetector({ detectLangStrategy: 'cookie' }))],
    [LocalizationStore, toFactory([Api], (api) => new LocalizationStore(api))],
    [Localization, toFactory([LocalizationStore], (locStore) => new Localization(locStore))],

    // auth
    [AuthCookieClient, toFactory([CookieStorage], (storage) => new AuthCookieClient(storage, { accessTokenName: 'student_access_token', refreshTokenName: 'student_refresh_token' }))],


    // stores
    [UserStore, toFactory([JwtDecoder, AuthCookieClient], (decoder, client) => new UserStore(decoder, client))],
    [TokenStore, toExisting(UserStore)],
    UIStore,
    SchoolStore,
    DeviceStore,
    ServiceStore,

    NotificationCenterStore,
)(observer(App));
