import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { TopicComment, TopicCommentFilter } from '@axl/accel-framework/models';

const replyCommentFields = `
        id,
        text,
        createdDate,
        sequenceNumber,
        student{
            id,
            firstName,
            lastName,
            email,
            avatar {
                id,
                cloudKey
            }
        }
    `;
const commentFields = `{
        id,
        text,
        createdDate,
        updatedDate,
        sequenceNumber,
        replyToComment{
           ${replyCommentFields}
        }
        student{
            id,
            firstName,
            lastName,
            email,
            avatar {
                id,
                cloudKey
            }
        },
        files{
            id,
            name,
            cloudKey,
            extension,
            size,
            type
        },
        likeCount,
        dislikeCount,
        currentUserReaction{
            id,
            type
        }
    }`;
export { commentFields };

export class FetchTopicCommentsRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public fields = commentFields;
    public url: string = '/forum/topic/comment';
    public body = {
        ...this.filter
    }

    constructor(public filter: TopicCommentFilter) {
        super();
    }
}

export class FetchTopicCommentsResponse extends ApiResponse {
    public items: TopicComment[];
    public filter: TopicCommentFilter;
    constructor(response: any) {
        super(response);
        this.items = this.body.items.map(TopicComment.fromJson);
        this.filter = new TopicCommentFilter(this.body.filter);
    }
}
