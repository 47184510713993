import { useInstance } from 'react-ioc';
import { SchoolStore, ServiceStore, UserStore } from 'stores';
import { Lang } from '@axl/accel-framework/enums';
import { LangDetector } from '@axl/accel-framework/utils';
import { useCallback, useMemo } from 'react';

export type SelectLangOptions = {
    reload?: boolean;
    /**
     * Skip server request
     */
    silent?: boolean;
}

export function useLang() {
    const userStore = useInstance(UserStore);
    const serviceStore = useInstance(ServiceStore);
    const langDetector = useInstance(LangDetector);
    const schoolStore = useInstance(SchoolStore);

    const lang = useMemo(() => {
        return langDetector.cookieLang || schoolStore.school?.lang;
    }, [schoolStore.school, langDetector.lang]);

    const selectLang = useCallback(async (lang: Lang,
        opts: SelectLangOptions = { reload: false, silent: true }) => {
        if (userStore.isAuthorized && opts.silent !== true)
            await serviceStore.selectLang(lang);
        langDetector.setLang(lang);
        if (opts.reload === true) location.reload();
    }, []);

    return { lang, selectLang };
}
