import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';

export class UpdateLocalizationRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = '/service/update-localization';
}

export class UpdateLocalizationResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}