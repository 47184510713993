import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class UnsubscribeEmailNotificationsRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = '/authorization/unsubscribe-email-notifications';
    public body: any = {
        studentId: this.studentId,
        email: this.email
    };

    constructor(public studentId: string, public email:string) {
        super();
    }
}

export class UnsubscribeEmailNotificationsResponse extends ApiResponse {
    constructor(response?: any) {
        super(response);
    }
}