import React, { useMemo } from 'react';
import { inject, provider, useInstance } from 'react-ioc';
import { observer } from 'mobx-react';
import Api from 'api';
import { BaseFilter } from '@axl/accel-framework/models';
import { CRUDListStore } from '@axl/accel-framework/stores/ListStore';
import { UtmTagApi } from 'api/methods/partnership';
import { SelectAsync } from '@axl/accel-framework/components';
import { SelectAsyncExternalProps } from '@axl/accel-framework/components/Select2';
import { useLoc } from 'components';
import { combineRefs } from '@axl/accel-framework/utils';
// import { ApiResult, ApiListResponse } from '@axl/accel-framework/api';


export declare type UtmTag = string;
export declare type UtmTagType = 'source' | 'medium' | 'campaign' | 'term' | 'content';

export class UtmTagFilter extends BaseFilter<UtmTag> {
    constructor(filter?: Partial<UtmTagFilter>) {
        super();
        if (filter) this.update(filter);
    }

    type: UtmTagType;
    direction: 'first' | 'last';

    update(changes: Partial<UtmTagFilter>) {
        super.update(changes);
    }
}

//@ts-ignore
export class UtmTagsStore extends CRUDListStore<UtmTag, UtmTagFilter, UtmTagApi> {

    constructor(private api: Api) {
        super('paging', false);
        this.api = api || inject(this, Api);
    }

    get crudApi(): UtmTagApi {
        return this.api.partnership.utm;
    }

    createFilter(p: Partial<UtmTagFilter>) {
        return new UtmTagFilter({ ...this.filter, ...p });
    }

//     //@ts-ignore
//     async fetchItems(fields?: string | undefined, filter?: UtmTagFilter | undefined): Promise<ApiResult<ApiListResponse<string, UtmTagFilter>>> {
//         const res = await super.fetchItems();
// res.response.items = ['tag1', 'tag2']
//         return res;
//     }
}

//@ts-ignore
export type ContactUtmTagsSelectProps = SelectAsyncExternalProps<UtmTag, UtmTagFilter> & {
    filter: Partial<UtmTagFilter>;
}

const UtmTagSelect: React.FC<ContactUtmTagsSelectProps> = (props) => {
    const loc = useLoc();
    const store = useInstance(UtmTagsStore);
    const selectRef = SelectAsync.useRef();

    useMemo(() => {
        store.updateFilter(props.filter);
        selectRef.current?.clearCache();
    }, [props.filter]);

    return <SelectAsync {...props}
        selectRef={combineRefs(props.selectRef, selectRef)}
        //@ts-ignore
        store={store}
        //@ts-ignore
        itemKey={x => x}
        ref={props.selectRef}
        placeholder={loc.word('UTMTagsSelector.placeholder', { default: 'Select UTM tags' })}
        itemLabel={x => x}
    />;
}

export default provider(UtmTagsStore)(observer(UtmTagSelect));