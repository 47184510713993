import { ApiListResponse, ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';
import { UserNotification, UserNotificationFilter } from '@axl/accel-framework/models';

export class FetchNotificationsRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public fields = `{
        id,
        type,
        payloadJson,
        createdDate,
        seenDate,
        adminId,
        studentId,
        adminTaskId,
        courseItemId,
        libraryItemId,
        orderId,
        orderPaymentId,
        scenarioId,
        chatConversationId,
        courseId,
        libraryId,
        courseItemTaskId,
        schoolContentItemCommentId,
    }`;
    //TODO taskAnswerId
    public url: string = `/notification`;
    public body = {
        ...this.filter,
    }

    constructor(public filter?: UserNotificationFilter, fields?: string) {
        super();
        this.fields = fields || this.fields;
    }
}

export class FetchNotificationsResponse extends ApiListResponse<UserNotification, UserNotificationFilter> {
    constructor(response: any) {
        super(response);
        this.items = this.body.items.map(UserNotification.fromJson);
        this.filter = new UserNotificationFilter(this.body.filter);
    }
}