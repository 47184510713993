import { HttpMethod, ApiRequest, ApiResponse, Optional, toOptional } from '@axl/accel-framework/api';
import { UserNotification } from '@axl/accel-framework/models';
import { type Moment } from 'moment';

export type SaveNotificationModel = {
    id: string;
    seenDate: Optional<Moment>;
}

export class SaveNotificationRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = '/notification';

    public body: SaveNotificationModel = {
        id: this.notification.id!,
        seenDate: toOptional(this.notification.seenDate),
    }

    constructor(public notification: Partial<UserNotification>) {
        super();
    }
}

export class SaveNotificationResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}