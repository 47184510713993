import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { TopicComment } from '@axl/accel-framework/models';
import { commentFields } from './fetch-topic-comments';

export type CreateTopicCommentRequestModel = {
    topicId: string,
    replyId?: string,
    text: string,
    files: string[]
}
export class CreateTopicCommentRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = '/forum/topic/comment';
    public body = {
        ...this.model,
        fields: commentFields
    }

    constructor(public model: CreateTopicCommentRequestModel) {
        super();
    }
}

export class CreateTopicCommentResponse extends ApiResponse {
    comment: TopicComment;
    itemsTotal: number;
    constructor(response: any) {
        super(response);
        this.comment = TopicComment.fromJson(this.body.comment);
        this.itemsTotal = this.body.itemsTotal;
    }
}