import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export type UpdatePasswordModel = {
    password: string;
};

export class UpdatePasswordRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public body = {
        ...this.model
    }
    public url: string = `/student/update-password`;

    constructor(public model: UpdatePasswordModel) {
        super();
    }
}

export class UpdatePasswordResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}