import Api from 'api';
import { FetchStudentCourseItemsRequest, FetchStudentCourseItemsResponse } from './types/fetch-student-course-items';
import { FetchStudentCourseItemRequest, FetchStudentCourseItemResponse } from './types/fetch-student-course-item';
import { CompleteCourseItemTheoryRequest, CompleteCourseItemTheoryResponse } from './types/complete-course-item-theory';
import { CreateTaskAnswerRequest, CreateTaskAnswerResponse } from './types/create-task-answer';
import { SaveTaskAnswerRequest, SaveTaskAnswerResponse } from './types/save-task-answer';
import { CreateTaskAnswerCommentRequest, CreateTaskAnswerCommentResponse } from './types/create-task-answer-comment';
import { ResetTaskAnswerStatusRequest, ResetTaskAnswerStatusResponse } from './types/reset-task-answer-status';
import { DeleteTaskAnswerCommentRequest, DeleteTaskAnswerCommentResponse } from './types/delete-task-answer-comment';
import { SaveTaskAnswerCommentRequest, SaveTaskAnswerCommentResponse } from './types/save-task-answer-comment';
import { FetchTaskAnswerCommentsRequest, FetchTaskAnswerCommentsResponse } from './types/fetch-task-answer-comments';
import StudentCourseProgress from 'models/student-course/student-course-progress/student-course-progress';

export default class StudentCourseItemApi {
    constructor(private api: Api) {
    }

    public async fetch(request: FetchStudentCourseItemsRequest) {
        return await this.api.execute(request, FetchStudentCourseItemsResponse);
    }

    public async fetchItem(request: FetchStudentCourseItemRequest) {
        return await this.api.execute(request, FetchStudentCourseItemResponse);
    }

    public async completeTheory(request: CompleteCourseItemTheoryRequest) {
        return await this.api.execute(request, CompleteCourseItemTheoryResponse);
    }

    completeTest = (studentCourseItemProgressId: string, courseItemTestId: string, isRight: boolean) =>
        this.api.post(`/student-course-item/complete-test`, { studentCourseItemProgressId, courseItemTestId, isRight }, json => StudentCourseProgress.fromJson(json));

    public async createTaskAnswer(request: CreateTaskAnswerRequest) {
        return await this.api.execute(request, CreateTaskAnswerResponse);
    }

    public async saveTaskAnswer(request: SaveTaskAnswerRequest) {
        return await this.api.execute(request, SaveTaskAnswerResponse);
    }

    public async createTaskAnswerComment(request: CreateTaskAnswerCommentRequest) {
        return await this.api.execute(request, CreateTaskAnswerCommentResponse);
    }

    public async saveTaskAnswerComment(request: SaveTaskAnswerCommentRequest) {
        return await this.api.execute(request, SaveTaskAnswerCommentResponse);
    }

    public async deleteTaskAnswerComment(request: DeleteTaskAnswerCommentRequest) {
        return await this.api.execute(request, DeleteTaskAnswerCommentResponse);
    }

    public async resetTaskAnswerStatus(request: ResetTaskAnswerStatusRequest) {
        return await this.api.execute(request, ResetTaskAnswerStatusResponse);
    }

    public async fetchTaskAnswerComments(request: FetchTaskAnswerCommentsRequest) {
        return await this.api.execute(request, FetchTaskAnswerCommentsResponse);
    }
}
