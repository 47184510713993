import { observable, action, computed } from 'mobx';
import { inject } from 'react-ioc';
import { Lang } from '@axl/accel-framework/enums';
import { DeviceStore } from '@axl/accel-framework/stores';
import { LangDetector } from '@axl/accel-framework/utils';
import type { IEZChatController, IEZPhoneController } from '@axl/ez-chat';

export default class UIStore {
    @observable isMenuCollapsed: boolean;
    @action async toggleMenu() {
        this.isMenuCollapsed = !this.isMenuCollapsed;
    }

    @observable isEmailConfirmationModalVisible: boolean = false;
    @action setIsEmailConfirmationModalVisible(val: boolean) { this.isEmailConfirmationModalVisible = val; }

    @observable isPhoneConfirmationModalVisible: boolean = false;
    @action setIsPhoneConfirmationModalVisible(val: boolean) { this.isPhoneConfirmationModalVisible = val; }

    @observable isProfileDrawerVisible: boolean = false;
    @observable defaultProfileTab: 'common' | 'notifications' | 'bonus' = 'common';
    @action setIsProfileDrawerVisible(val: boolean, defaultTab?: UIStore['defaultProfileTab']) {
        this.isProfileDrawerVisible = val;
        this.defaultProfileTab = defaultTab ?? 'common';
    }

    @computed get isMobile() {
        return this.deviceStore.width <= 600;
    }

    @computed get deviceSize() {
        return this.deviceStore.size;
    }

    get device() {
        return this.deviceStore;
    }

    constructor(private langDetector: LangDetector,
        private deviceStore: DeviceStore) {
        this.langDetector = langDetector || inject(this, LangDetector);
        this.deviceStore = deviceStore || inject(this, DeviceStore);
        this.isMenuCollapsed = this.deviceSize != 'desktop';
    }



    @computed get dateFormat(): string {
        switch (this.langDetector.lang) {
            case Lang.en: return 'DD MMMM YYYY';
            case Lang.ru: return 'DD MMMM YYYY';
            default: return 'DD MMMM YYYY';
        }
    }

    @computed get dateTimeFormat(): string {
        return `${this.dateFormat} HH:mm`;
    }

    getDateTimeFormat(includeTime: boolean = true, includeSeconds: boolean = false): string {
        let format = this.dateFormat;
        if (includeTime)
            format = `${format} ${this.dateTimeFormat}`;
        if (includeSeconds) format += ':ss';
        return format;
    }

    @observable chatController: IEZChatController;
    @action setChatController(chatController: IEZChatController) {
        this.chatController = chatController;
    }

    @observable phoneController: IEZPhoneController;
    @action setPhoneController(phoneController: IEZPhoneController) {
        this.phoneController = phoneController;
    }
}