import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class StartCourseRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public body = {
        courseId: this.courseId
    }
    public url: string = `/student-course`;

    constructor(public courseId: string) {
        super();
    }
}

export class StartCourseResponse extends ApiResponse {
    public studentCourseId: string;
    public currentCourseItemId: string;
    constructor(response: any) {
        super(response);
        this.studentCourseId = this.body.studentCourseId;
        this.currentCourseItemId = this.body.currentCourseItemId;
    }
}