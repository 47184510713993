import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { Student } from 'models';

export class FetchMeRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = '/authorization/me';
}

export class FetchMeResponse extends ApiResponse {
    public student: Student;
    constructor(response: any) {
        super(response);
        this.student = Student.fromJson(this.body);
    }
}