import Api from 'api';
import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';
import { ContactLicense, ContactLicenseFilter } from '@axl/accel-framework/models';

export default class LicenseApi {
    constructor(private api: Api) { }

    public async fetch(request: FetchLicensesRequest) {
        return await this.api.execute(request, FetchLicensesResponse);
    }
}


export class FetchLicensesRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = '/student/license';
    public body = {
        ...this.filter,
    }

    constructor(public filter: ContactLicenseFilter, fields?: string) {
        super();
        this.fields = fields ?? `{
            id,
            beginDate,
            endDate,
            type,
            coursePlan {
                id,
                name,
                softDeleted,
                course {
                    id,
                    name,
                    softDeleted
                }
            },
            course {
                id,
                name,
                softDeleted,
                primaryImage{
                    id,
                    file{
                        id,
                        name,
                        cloudKey,
                        extension,
                        size,
                        type
                    }
                }
            },
            libraryAccess{
                library{
                    id,
                    name,
                    softDeleted,
                    primaryImage{
                        id,
                        file{
                            id,
                            name,
                            cloudKey,
                            extension,
                            size,
                            type
                        }
                    }
                }
            }
        }`;
    }

}

export class FetchLicensesResponse extends ApiResponse {
    public filter: ContactLicenseFilter;
    public items: ContactLicense[];
    constructor(response: any) {
        super(response);
        this.filter = new ContactLicenseFilter(this.body.filter);
        this.items = this.body.items.map(ContactLicense.fromJson);
    }
}