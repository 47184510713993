import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class ResendEmailConfirmationRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = `/student/resend-email-confirmation`;
}

export class ResendEmailConfirmationResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}