import { runInAction } from 'mobx';
import { observable, action, computed } from 'mobx';
import { inject } from 'react-ioc';
import Api from 'api';
import { FetchSchoolSettingsRequest } from 'api/methods/school-settings/types/fetch-school-settings';
import { School } from 'models';

export default class SchoolStore {
    @observable ready: boolean = false;
    @observable school: School;

    constructor(private api: Api) {
        this.api = api || inject(this, Api);
    }

    @computed get hasUserFooter() {
        return this.school.settings.isPageFooterEnabled;
    }

    @action async fetchSchool() {
        this.ready = false;
        const result = await this.api.schoolSettings.fetch(new FetchSchoolSettingsRequest());
        if (result.success)
            runInAction(() => {
                this.school = result.response.school;
                this.ready = true;
            });
    }
}
