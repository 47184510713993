import Api from 'api';
import { FetchSchoolSettingsRequest, FetchSchoolSettingsResponse } from './fetch-school-settings';

export default class SchoolSettingsApi {
    constructor(private api: Api) {
    }

    public async fetch(request: FetchSchoolSettingsRequest) {
        return await this.api.execute(request, FetchSchoolSettingsResponse);
    }
}