import { observable, action } from 'mobx';
import { inject } from 'react-ioc';
import { HubClient } from '@axl/accel-framework/hub-client';
import { NotificationPayload } from 'hub-client/payloads/notifications';
import NotificationSubscription from 'hub-client/subscriptions/NotificationSubscription';
import { Disposable } from '@axl/accel-framework/utils';

export default class NotificationCenterStore extends Disposable {
    @observable notifications = new Map<string, NotificationPayload>();

    constructor(private hub: HubClient) {
        super();
        this.hub = hub || inject(this, HubClient);
    }

    @action start() {
        this.toDispose(this.hub.on(new NotificationSubscription(), async payload => {
            console.logDev(`[NotificationCenter] ${payload.type}`, payload);
            this.addNotification(payload);
        }));
    }

    @action addNotification(payload: NotificationPayload) {
        this.notifications.set(payload.key, payload);
    }

    @action stop(clear = false) {
        if (clear) this.clear();
        this.dispose();
    }

    @action close(key: string) {
        this.notifications.delete(key);
    }

    @action clear(keys?: string[]) {
        if (keys) {
            keys.forEach(key => this.notifications.delete(key));
        } else {
            this.notifications.clear();
        }
    }
}