import Api from 'api';
import { FetchStudentCoursesRequest, FetchStudentCoursesResponse } from './types/fetch-student-courses';
import { FetchStudentCourseRequest, FetchStudentCourseResponse } from './types/fetch-student-course';
import { StartCourseRequest, StartCourseResponse } from './types/start-course';
import { SendFeedbackRequest, SendFeedbackResponse } from './types/send-feedback';

export default class StudentCourseApi {
    constructor(private api: Api) {
    }

    public async fetch(request: FetchStudentCoursesRequest) {
        return await this.api.execute(request, FetchStudentCoursesResponse);
    }

    public async fetchItem(request: FetchStudentCourseRequest) {
        return await this.api.execute(request, FetchStudentCourseResponse);
    }

    public async startCourse(request: StartCourseRequest) {
        return await this.api.execute(request, StartCourseResponse);
    }

    public async sendFeedback(request: SendFeedbackRequest) {
        return await this.api.execute(request, SendFeedbackResponse);
    }
}