import Api from 'api';
import { FetchSchoolCoursesRequest, FetchSchoolCoursesResponse } from './types/fetch';

export default class SchoolCourseApi {
    constructor(private api: Api) {
    }

    public async fetch(request: FetchSchoolCoursesRequest) {
        return await this.api.execute(request, FetchSchoolCoursesResponse);
    }
}