import { ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { HttpMethod } from '@axl/accel-framework/api';
import { SchoolContentItemProgressRating } from '@axl/accel-framework/models';

export class SaveCourseItemRatingRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public url: string = `/school-content-item-rating`;
    public body = {
        ...this.rating,
        fileIds: this.rating.files?.map(i => i.id) ?? [],
    }

    constructor(public rating: Partial<SchoolContentItemProgressRating>) {
        super();
    }
}

export class SaveCourseItemRatingResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}