import { ApiRequest, ApiResponse, HttpMethod, Optional, toOptional } from '@axl/accel-framework/api';
import { UserNotificationChannel } from '@axl/accel-framework/models';

export type SaveNotificationChannelModel = {
    id: string;
    useSite: Optional<boolean>;
    useEmail: Optional<boolean>;
    useTg: Optional<boolean>;
}

export class SaveNotificationChannelRequest extends ApiRequest {
    public method = HttpMethod.PUT;
    public body: SaveNotificationChannelModel = {
        id: this.id,
        useSite: toOptional(this.changes.useSite),
        useEmail: toOptional(this.changes.useEmail),
        useTg: toOptional(this.changes.useTg),
    }
    public url: string = `/notification/channel`;

    constructor(public id: string, public changes: Partial<UserNotificationChannel>) {
        super();
    }
}

export class SaveNotificationChannelResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}