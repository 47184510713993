import { HubClient } from '@axl/accel-framework/hub-client';
import { useApi, useSchool } from 'components';
import GamificationBalanceSubscription from 'hub-client/subscriptions/gamification';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useInstance } from 'react-ioc';
import { UserStore } from 'stores';

type Props = {
}
const GamificationBalanceProvider: React.FC<Props> = ({ children }) => {
    const userStore = useInstance(UserStore);
    const school = useSchool();
    const hub = useInstance(HubClient);
    const api = useApi();

    useEffect(() => {
        if (!school.gamification || !userStore.isAuthorized) return;
        (async () => {
            const res = await api.gamification.fetchBalance();
            userStore.setGamificationBalance(res.response?.body ?? 0);
        })();
        const sub = hub.on(new GamificationBalanceSubscription(), score => userStore.setGamificationBalance(score));
        return () => sub.dispose();
    }, [school.gamification, userStore.isAuthorized]);

    return <>{children}</>;
}

export default observer(GamificationBalanceProvider);