import { action, observable } from 'mobx';
import { Contact } from '@axl/accel-framework/models';
import { StudentSession } from 'models';

export declare type StudentTag = {
    id: string;
    name: string;
}
export declare type StudentGroup = {
    id: string;
    name: string;
}
export default class Student extends Contact {
    constructor(student?: Partial<Student>) {
        super(student);
        if (student)
            this.update(student);
    }

    @observable session: StudentSession;
    @observable tags: StudentTag[];
    @observable groups: StudentGroup[];
    @observable bonusBalance: number;

    static fromJson(json: any) {
        return new Student({
            ...Contact.fromJson(json),
            session: json.session ? StudentSession.fromJson(json.session) : undefined,
            tags: json.tags ?? [],
            groups: json.groups ?? [],
            bonusBalance: json.bonusBalance ?? 0
        });
    }

    @action update(student: Partial<Student>) {
        super.update(student);
    }
}