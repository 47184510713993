import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { StudentCourseItem } from 'models';

export class FetchCourseItemsRequest extends ApiRequest {
    public method = HttpMethod.GET;

    public url: string = `/course-preview/${this.courseId}/items`;
    public body: any = {
        planId: this.planId
    };

    constructor(public courseId: string, public planId?: string) {
        super();
    }
}

export class FetchCourseItemsResponse extends ApiResponse {
    items: StudentCourseItem[];
    constructor(response: any) {
        super(response);
        this.items = this.body.items.map(StudentCourseItem.fromJson);
    }
}