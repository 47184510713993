import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import StudentCourseFilter from 'models/student-course/student-course-filter';
import { StudentCourse } from 'models';

export class FetchStudentCoursesRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public fields = `{
        id,
        state,
        progress,
        course{
            id,
            name,
            shortDescription,
            primaryImage{
                file{
                    id,
                    cloudKey
                }
            }
        }
    }`;
    public url: string = '/student-course';
    public body = {
        ...this.filter,
    }

    constructor(public filter?: StudentCourseFilter) {
        super();
    }
}

export class FetchStudentCoursesResponse extends ApiResponse {
    public filter: StudentCourseFilter;
    public items: StudentCourse[];
    constructor(response: any) {
        super(response);
        this.filter = new StudentCourseFilter(this.body.filter);
        this.items = this.body.items.map(StudentCourse.fromJson);
    }
}