import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class ContactInFilterRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = '/service/contact-in-filter';

    public body: any = {
        f: this.filterId,
        c: this.contactId
    };

    constructor(public filterId: string, public contactId: string) {
        super();
    }
}

export class ContactInFilterResponse extends ApiResponse {
    public isInFilter: boolean;
    constructor(response?: any) {
        super(response);
        this.isInFilter = response.body.isInFilter;
    }
}