import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export type BlockStudentRequestModel = {
    threadId: string;
    studentId: string;
    reason: string;
    beginDate: Date;
    endDate: Date | null;
    topicId?: string;
    commentId?: string;
}

export class BlockStudentRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = `/forum/block-student`;
    public body = {
        ...this.model
    }

    constructor(public model: BlockStudentRequestModel) {
        super();
    }
}

export class BlockStudentResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}