import Api from 'api';
import { FetchCourseItemsRequest, FetchCourseItemsResponse } from './types/fetch-course-items';
import { FetchCourseItemRequest, FetchCourseItemResponse } from './types/fetch-course-item';
import { FetchCourseRequest, FetchCourseResponse } from './types/fetch-course';
import { FetchCoursePlansRequest, FetchCoursePlansResponse } from './types/fetch-course-plans';

export default class CoursePreviewApi {
    constructor(private api: Api) {
    }

    public async fetchCourse(request: FetchCourseRequest) {
        return await this.api.execute(request, FetchCourseResponse);
    }

    public async fetchCourseItem(request: FetchCourseItemRequest) {
        return await this.api.execute(request, FetchCourseItemResponse);
    }

    public async fetchCourseItems(request: FetchCourseItemsRequest) {
        return await this.api.execute(request, FetchCourseItemsResponse);
    }

    public async fetchCoursePlans(request: FetchCoursePlansRequest){
        return await this.api.execute(request, FetchCoursePlansResponse);
    }
}