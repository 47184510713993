import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export class DeleteReactionRequest extends ApiRequest {
    public method = HttpMethod.DELETE;
    public url: string = `/forum/reaction/${this.reactionId}`;

    constructor(public reactionId: string) {
        super();
    }
}

export class DeleteReactionResponse extends ApiResponse {
    constructor(response: any) {
        super(response);
    }
}