import { HttpMethod, ApiRequest, ApiResponse } from '@axl/accel-framework/api';
import { UserNotificationType } from '@axl/accel-framework/models';

type CreateNotificationChannelModel = {
    notificationType: UserNotificationType;
    useSite: boolean;
    useEmail: boolean;
    useTg: boolean;
}

export class CreateNotificationChannelRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = '/notification/channel';

    constructor(public model: CreateNotificationChannelModel) {
        super();
        this.body = model;
    }
}

export class CreateNotificationChannelResponse extends ApiResponse {
    public id: string;
    constructor(response: any) {
        super(response);
        this.id = this.body;
    }
}