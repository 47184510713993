import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { StudentCourseItem } from 'models';

export class FetchStudentCourseItemsRequest extends ApiRequest {
    public method = HttpMethod.GET;

    public url: string = `/student-course-preview/${this.studentCourseId}/items`;

    constructor(public studentCourseId: string) {
        super();
    }
}

export class FetchStudentCourseItemsResponse extends ApiResponse {
    items: StudentCourseItem[];
    constructor(response: any) {
        super(response);
        this.items = this.body.items.map(StudentCourseItem.fromJson);
    }
}