import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export type CreateTopicRequestModel = {
    threadId: string,
    title: string,
    text: string,
    files: string[]
}
export class CreateTopicRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = '/forum/topic';
    public body = {
        ...this.model
    }

    constructor(public model: CreateTopicRequestModel) {
        super();
    }
}

export class CreateTopicResponse extends ApiResponse {
    id: string;
    constructor(response: any) {
        super(response);
        this.id = this.body;
    }
}