import { HubSubscription } from "@axl/accel-framework/hub-client";
import { GamificationAchievementPayload, NotificationPayload } from "hub-client/payloads/notifications";

export default class NotificationSubscription extends HubSubscription<NotificationPayload> {
    method = `Notification`;
    protected processPayload = (json: any) => {
        switch (json.type) {
            case 'gamificationAchievement':
                return GamificationAchievementPayload.fromJson(json);
            default:
                throw new Error('[NotificationSubscription] invalid notification type');
        }
    };
}