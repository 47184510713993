import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { StudentCourseItem } from 'models';

export class FetchStudentCourseItemRequest extends ApiRequest {
    public method = HttpMethod.GET;

    constructor(public studentCourseId: string, public itemId: string) {
        super();
        this.url = `/student-course-preview/${this.studentCourseId}/items/${this.itemId}`;
    }
}

export class FetchStudentCourseItemResponse extends ApiResponse {
    studentCourseItem: StudentCourseItem;
    constructor(response: any) {
        super(response);
        this.studentCourseItem = StudentCourseItem.fromJson(this.body);
    }
}