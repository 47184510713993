// abortcontroller-polyfill обеспечивает поддержку функциональности AbortController и метода fetch в старых браузерах (Chrome 66 и ниже)
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

// #region Chrome 49
import 'core-js/features/object/values';
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}
// #endregion