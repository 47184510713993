import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { SchoolContentItem, SchoolContentItemFilter } from 'models';

export class FetchSchoolCoursesRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = `/school-course`;
    public body = {
        ...this.filter
    }

    constructor(public filter: SchoolContentItemFilter, fields?: string) {
        super();
        if (fields)
            this.fields = fields;
    }
}

export class FetchSchoolCoursesResponse extends ApiResponse {
    public items: SchoolContentItem[];
    public filter: SchoolContentItemFilter;
    constructor(response: any) {
        super(response);
        this.items = this.body.items.map(SchoolContentItem.fromJson);
        this.filter = new SchoolContentItemFilter(this.body.filter);
    }
}
