import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { Lang } from '@axl/accel-framework/enums';

export type RegisterRequestModel = {
    contactId?: string;
    email: string;
    password: string;
    confirmPassword: string;
    lang: Lang;
    timezoneOffset: string;
    urlParameters: any;
    isAgreementAccepted: boolean;
    inviteToken?: string;
}

export class RegisterRequest extends ApiRequest {
    public method = HttpMethod.POST;
    public url: string = '/authorization/register';
    public body: any = {
        ...this.model
    };
    public authorized = false;

    constructor(public model: RegisterRequestModel) {
        super();
    }
}

export class RegisterResponse extends ApiResponse {
    constructor(response?: any) {
        super(response);
    }
}