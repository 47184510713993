import { ContactCourse, Contact, Course, CourseItem } from '@axl/accel-framework/models';
import { action, computed, observable } from 'mobx';
import moment from 'moment';

export default class StudentCourse extends ContactCourse {
    constructor(item?: Partial<StudentCourse>) {
        super(item);
        if (item) this.update(item);
    }

    /**
     * sections excluded
     */
    totalItemCount: number;
    initialCourseItemId: string;
    @observable flowBeginDate?: moment.Moment;
    @observable accessBeginDate?: moment.Moment;
    @observable accessEndDate?: moment.Moment;
    @observable isSubscription: boolean;

    static fromJson(json: any): StudentCourse {
        return new StudentCourse({
            id: json.id,
            softDeleted: json.softDeleted,
            state: json.state,
            progress: json.progress,
            feedback: json.feedback,
            score: json.score,
            lastActivityDate: json.lastActivityDate ? new Date(json.lastActivityDate) : undefined,
            contact: json.student ? Contact.fromJson(json.student) : undefined,
            course: json.course ? Course.fromJson(json.course) : undefined,
            initialCourseItemId: json.currentCourseItem?.id,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            beginDate: json.beginDate ? new Date(json.beginDate) : undefined,
            endDate: json.endDate ? new Date(json.endDate) : undefined,
            flowBeginDate: json.flowBeginDate ? moment(json.flowBeginDate) : undefined,
            accessBeginDate: json.accessBeginDate ? moment(json.accessBeginDate) : undefined,
            accessEndDate: json.accessEndDate ? moment(json.accessEndDate) : undefined,
            isSubscription: json.isSubscription ?? false,
            currentCourseItem: json.currentCourseItem ? CourseItem.fromJson(json.currentCourseItem) : undefined,
        });
    }

    @action
    update(studentCourse: Partial<StudentCourse>) {
        super.update(studentCourse);
    }

    @computed
    get isInFlow() {
        return this.flowBeginDate != null;
    }
}
