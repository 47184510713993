import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';
import { SchoolLibraryItem } from 'models';

export class FetchSchoolLibraryItemRequest extends ApiRequest {
    public method = HttpMethod.GET;
    private filesSelector = `files(filter:"{take:0}") {
        id,
        name,
        cloudKey,
        extension,
        size,
        type
    }`;
    public fields = `{
        id,
        title,
        type,
        designHTMLPublished,
        designStylePublished,
        ratingEnabled,
        progress{
            id,
            beginDate,
            rating {
                id,
                comment,
                rating,
                createdDate,
                updatedDate,
                ${this.filesSelector}
            }
        },

    }`;
    public url: string = `/school-library-item/${this.libraryId}/${this.itemId}`;

    constructor(public libraryId: string, public itemId: string) {
        super();
    }
}

export class FetchSchoolLibraryItemResponse extends ApiResponse {
    libraryItem: SchoolLibraryItem;
    constructor(response: any) {
        super(response);
        this.libraryItem = SchoolLibraryItem.fromJson(this.body);
    }
}