import Api from 'api';
import { SaveStudentRequest, SaveStudentResponse } from './types/save-student';
import { ResendEmailConfirmationRequest, ResendEmailConfirmationResponse } from './types/resend-email-confirmation';
import { SelectLangRequest, SelectLangResponse } from './types/select-lang';
import { UpdatePasswordRequest, UpdatePasswordResponse } from './types/update-password';
import SubscriptionApi from './subscription';
import { CrudApi } from '@axl/accel-framework/api';
import { PurchaseOrder, StudentBonusTransaction } from '@axl/accel-framework/models';
import LicenseApi from './license';

export default class StudentApi {
    constructor(private api: Api) { }

    public async save(request: SaveStudentRequest) {
        return await this.api.execute(request, SaveStudentResponse);
    }

    public async updatePassword(request: UpdatePasswordRequest) {
        return await this.api.execute(request, UpdatePasswordResponse);
    }

    public async resendEmailConfirmation(request: ResendEmailConfirmationRequest) {
        return await this.api.execute(request, ResendEmailConfirmationResponse);
    }

    public async selectLang(request: SelectLangRequest) {
        return await this.api.execute(request, SelectLangResponse);
    }

    public license = new LicenseApi(this.api);
    public subscription = new SubscriptionApi(this.api);
    public order = new OrderApi(this.api);
    bonusTransaction = new BonusTransactionApi(this.api);
}

export class OrderApi extends CrudApi<PurchaseOrder> {

    constructor(api: Api) {
        super(api, {
            endpoint: '/student/order',
            parse: e => PurchaseOrder.fromJson(e),
            defaultFields: `{
                id,
                createdDate,
                paidDate,
                currency,
                isDigitalDelivery,
                fullyPaid,
                shippingPrice,
                totalAmount,
                paidAmount,
                savingsAmount,
                status,
                completed,
                number,
                contents{
                    id,
                    quantity,
                    product{
                        id,
                        name,
                        type,
                        price,
                        softDeleted
                    }
                }
            }`
        });
    }
}

export class BonusTransactionApi extends CrudApi<StudentBonusTransaction> {
    constructor(api: Api) {
        super(api, {
            endpoint: '/student/bonus-transaction',
            parse: e => StudentBonusTransaction.fromJson(e),
            defaultFields: `{
                id,
                amount,
                comment,
                createdDate,
                order{
                    id,
                    number
                },
                type,
                transaction{
                    id
                },
                validUntilDate
            }`
        });
    }
}