export default class NewRelicSettings {
    public key: string;
    public appId: string;
    public accountId: string;
    public enabled: boolean;

    constructor(settings: any) {
        this.key = settings.key;
        this.appId = settings.appId;
        this.accountId = settings.accountId;
        this.enabled = settings.enabled;
    }
}