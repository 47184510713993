import { action } from "mobx";
import { School as BaseSchool, SchoolProfileSettings } from "@axl/accel-framework/models";

export default class School extends BaseSchool {
    constructor(settings: Partial<School>) {
        super();
        if (settings)
            this.update(settings);
    }

    isLocked: boolean;

    @action
    update(entity: Partial<School>) {
        super.update(entity);
    }

    static fromJson(json: any): School {
        return new School({
            ...BaseSchool.fromJson(json),
            // TODO rename response model to admin naming 'StudentProfileSettings'
            profileSettings: SchoolProfileSettings.fromJson(json.profileSettings),
            isLocked: json.isLocked,
        });
    }
}