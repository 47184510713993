import { ApiRequest, ApiResponse, HttpMethod } from '@axl/accel-framework/api';

export type FetchStatusResponseModel = {
    email: string;
    unsubscribed: boolean;
}

export class FetchStatusRequest extends ApiRequest {
    public method = HttpMethod.GET;
    public url: string = `/student/email-broadcast-subscription-status/${this.studentId}/${this.messageId}`;

    constructor(public studentId: string, public messageId: string) {
        super();
    }
}

export class FetchStatusResponse extends ApiResponse {
    status: FetchStatusResponseModel;
    constructor(response: any) {
        super(response);
        this.status = this.body;
    }
}