import Api from 'api';
import { FetchStatusRequest, FetchStatusResponse } from './types/fetch-status';
import { UnsubscribeRequest, UnsubscribeResponse } from './types/unsubscribe';

export default class StudentEmailBroadcastApi {
    constructor(private api: Api) { }

    public async fetchStatus(request: FetchStatusRequest) {
        return await this.api.execute(request, FetchStatusResponse);
    }

    public async unsubscribe(request: UnsubscribeRequest) {
        return await this.api.execute(request, UnsubscribeResponse);
    }
}